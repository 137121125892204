import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus, participateRanking } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}


export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {

  const caclElapsedTime = () => {
    const initTime = localStorage.getItem('initTime') == null ? 0 : localStorage.getItem('initTime');
    if (localStorage.getItem('endTime') == null) {
      const now = +new Date();
      localStorage.setItem('endTime', now.toString());
    }
    const endTime = localStorage.getItem('endTime');
    // @ts-ignore: Object is possibly 'null'.
    const elapsedTime = Math.abs(parseInt(endTime) - parseInt(initTime)) / 1000;
    localStorage.setItem('elapsedTime', elapsedTime.toString());
    return elapsedTime;
  }

  const hasSubmittedGameForRanking = () => {
    return localStorage.getItem('hasSubmittedGameForRanking') === 'true';
  }

  const hasSubmittedStats = () => {
    return localStorage.getItem('hasSubmittedStats') === 'true';
  }

  if ((isGameLost || isGameWon) && !hasSubmittedStats()) {
    localStorage.setItem('hasSubmittedStats', 'true');
    const payload = localStorage.getItem('gameState');
    fetch("https://us-central1-scholardle.cloudfunctions.net/save_stats", {
      method: "POST",
      headers: {'Content-Type': 'application/json'}, 
      body: payload,
    }).then(res => {
    });
  }

  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
        numberOfGuessesMade={numberOfGuessesMade}
      />
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-300 text-base font-medium text-gray-700 hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                guesses,
                isGameLost,
                isHardMode,
                isDarkMode,
                isHighContrastMode,
                handleShareToClipboard
              )
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
      {(isGameWon) && (!hasSubmittedGameForRanking()) && (
          <div id="ranking-div" className="mt-10 text-left">
            <p className="font-bold">Congrats, you solved it in {caclElapsedTime()} seconds!</p>
          </div>
      )}
      {(false) && (
        <div>
          <p>&nbsp;</p>
          <p>Would you like to appear in the Scholardle weekly winners list on Twitter? Enter your handle:</p>
          <div className="mt-5 sm:mt-6 columns-2">
            <div>
              <input
                id="handle"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                placeholder="@handle"/>
              </div>
            <div>
              <button
                type="button"
                className="w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-300 text-base font-medium text-gray-700 hover:bg-amber-400 focus:outline-none sm:text-sm"
                onClick={(event) => {
                  participateRanking(isGameLost)
                }}
              >
                I'm in!
              </button>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  )
}
