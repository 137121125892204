import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { GAME_TITLE } from '../../constants/strings'

const logoStyle = {
  height: '20px',
  display: 'inline-block',
  margin: '0px 3px 3px 3px',
};


type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  return (
    <div>
      <div className="navbar">
        <div className="navbar-content px-5">
          <InformationCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <p className="text-xl ml-2.5 font-bold dark:text-white">{GAME_TITLE}
            <a href={"https://www.writefull.com"} className='text-sm dark:text-white font-light'> by 
              <img src="writefull-logo.png" alt="Writefull Logo head" style={logoStyle} />
            writefull</a>
          </p>
          <div className="right-icons">
            <ChartBarIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsStatsModalOpen(true)}
            />
            <CogIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsSettingsModalOpen(true)}
            />
          </div>
        </div>
        <hr></hr>
      </div>
    </div>
  )
}
