export const WORDS = ['lesion',
'marker',
'thymus',
'turgor',
'solute',
'pineal',
'anther',
'nocebo',
'intron',
'apical',
'spleen',
'operon',
'cloaca',
'amoeba',
'cervix',
'fundal',
'supine',
'apiary',
'phylum',
'genome',
'mucous',
'stroma',
'quasar',
'pollen',
'cilium',
'serous',
'kinase',
'myelin',
'amnion',
'stamen',
'ligand',
'curare',
'suture',
'isobar',
'plexus',
'vacuum',
'chitin',
'lumbar',
'corpus',
'oocyte',
'biopsy',
'monera',
'lamina',
'sepsis',
'optics',
'coelom',
'photon',
'miosis',
'ketone',
'neuron',
'oedema',
'vernal',
]